import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store'

const HomePage = () => import('@/views/HomePage.vue')
const LoginPage = () => import('@/views/LoginPage.vue')
const BookPage = () => import('@/views/BookPage.vue')
const EditorPage = () => import('@/views/EditorPage.vue')
const RecommendPage = () => import('@/views/RecommendPage.vue')
const ExplainPage = () => import('@/views/ExplainPage.vue')
const RevisionPage = () => import('@/views/RevisionPage.vue')
const NotFoundPage = () => import('@/views/NotFoundPage.vue')

const GoogleCallback = () => import('@/components/Login/GoogleCallback.vue')
const NaverCallback = () => import('@/components/Login/NaverCallback.vue')
const KakaoCallback = () => import('@/components/Login/KakaoCallback.vue')

const routes = [
  { 
    path: '/', 
    name: 'home',
    component: HomePage,
    meta: { 
      requiresAuth: false,
      title: '쓰담',
    },
  },
  { 
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { 
      requiresAuth: false,
      title: '쓰담: 로그인',
    },

  },
  {
    path: '/login/google',
    name: 'googleCallback',
    component: GoogleCallback,
    meta: { requiresAuth: false},
  },
  {
    path: '/login/naver',
    name: 'naverCallback',
    component: NaverCallback,
    meta: { requiresAuth: false},
  },
  {
    path: '/login/kakao',
    name: 'kakaoCallback',
    component: KakaoCallback,
    meta: { requiresAuth: false},
  },
  {
    path: '/book', 
    name: 'book',
    component: BookPage,
    meta: { 
      requiresAuth: true,
      title: '쓰담: 내 서재',
    },
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: RecommendPage,
    meta: { 
      requiresAuth: true,
      title: '쓰담: 글감 추천',
    },
  },
  { 
    path: '/editor', 
    name: 'editor',
    component: EditorPage,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: '/revision',
    name: 'revision',
    component: RevisionPage,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: '/explain',
    name: 'explain',
    component: ExplainPage,
    meta: { 
      requiresAuth: false,
      title: '쓰담: 서비스 소개',
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundPage,
    meta: { 
      requiresAuth: false,
      title: '오류: 페이지를 찾지 못했습니다.',
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "쓰담"
  // 어떤 화면으로 가도 프린트 모달 초기화
  store.commit('SET_SHOW_PRINT_MODAL', false)
  if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
    next({ name: 'login'})
    return
  }
  if (to.name == 'editor') {
    if (store.getters.currentBook == null) {
      next({ name: 'book'})
      return
    }
  }
  if (to.name == 'recommend') {
    if (store.getters.currentBook == null) {
      next({ name: 'book'})
      return
    }
  }
  if (to.name == 'revision') {
    if (store.getters.currentPageID == null) {
      next({ name: 'editor'})
      return
    }
  }
  next()
})

export default router



// import { createWebHistory, createRouter } from 'vue-router'
// import store from '@/store'

// const HomePage = () => import('@/views/HomePage.vue')
// const LoginPage = () => import('@/views/LoginPage.vue')
// const BookPage = () => import('@/views/BookPage.vue')
// const EditorPage = () => import('@/views/EditorPage.vue')
// const RecommendPage = () => import('@/views/RecommendPage.vue')
// const ExplainPage = () => import('@/views/ExplainPage.vue')
// const RevisionPage = () => import('@/views/RevisionPage.vue')
// const NotFoundPage = () => import('@/views/NotFoundPage.vue')

// const GoogleCallback = () => import('@/components/Login/GoogleCallback.vue')
// const NaverCallback = () => import('@/components/Login/NaverCallback.vue')
// const KakaoCallback = () => import('@/components/Login/KakaoCallback.vue')

// const routes = [
//   { 
//     path: '/', 
//     name: 'home',
//     component: HomePage,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담',
//       metaTags: [
//         {
//           name: '홈페이지',
//           content: '쓰담: 이야기를 쓰고, 생각을 담는 공간'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담'
//         }
//       ]
//     },
//   },
//   { 
//     path: '/login',
//     name: 'login',
//     component: LoginPage,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담: 로그인',
//       metaTags: [
//         {
//           name: '로그인',
//           content: '로그인하여 쓰담 서비스를 이용해 보세요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 로그인'
//         }
//       ]
//     },
//   },
//   {
//     path: '/login/google',
//     name: 'googleCallback',
//     component: GoogleCallback,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담: 구글 로그인',
//       metaTags: [
//         {
//           name: '구글 로그인',
//           content: '구글 계정으로 로그인하세요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 구글 로그인'
//         }
//       ]
//     },
//   },
//   {
//     path: '/login/naver',
//     name: 'naverCallback',
//     component: NaverCallback,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담: 네이버 로그인',
//       metaTags: [
//         {
//           name: '네이버 로그인',
//           content: '네이버 계정으로 로그인하세요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 네이버 로그인'
//         }
//       ]
//     },
//   },
//   {
//     path: '/login/kakao',
//     name: 'kakaoCallback',
//     component: KakaoCallback,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담: 카카오 로그인',
//       metaTags: [
//         {
//           name: '카카오 로그인',
//           content: '카카오 계정으로 로그인하세요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 카카오 로그인'
//         }
//       ]
//     },
//   },
//   {
//     path: '/book', 
//     name: 'book',
//     component: BookPage,
//     meta: { 
//       requiresAuth: true,
//       title: '쓰담: 내 서재',
//       metaTags: [
//         {
//           name: '내 서재',
//           content: '내가 쓴 책을 한 눈에 확인할 수 있어요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 내 서재'
//         }
//       ]
//     },
//   },
//   {
//     path: '/recommend',
//     name: 'recommend',
//     component: RecommendPage,
//     meta: { 
//       requiresAuth: true,
//       title: '쓰담: 글감 추천',
//       metaTags: [
//         {
//           name: '글감 추천',
//           content: '개인 맞춤형 글감으로 이야기를 시작해 보세요.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 글감 추천'
//         }
//       ]
//     },
//   },
//   { 
//     path: '/editor', 
//     name: 'editor',
//     component: EditorPage,
//     meta: { 
//       requiresAuth: true,
//       title: '쓰담: 에디터',
//       metaTags: [
//         {
//           name: '에디터',
//           content: '글을 쓰고, 수정하고, 완성할 수 있는 공간입니다.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 에디터'
//         }
//       ]
//     },
//   },
//   {
//     path: '/revision',
//     name: 'revision',
//     component: RevisionPage,
//     meta: { 
//       requiresAuth: true,
//       title: '쓰담: 퇴고하기',
//       metaTags: [
//         {
//           name: '퇴고하기',
//           content: '작성한 글을 편리하게 퇴고할 수 있는 공간입니다.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 퇴고하기'
//         }
//       ]
//     },
//   },
//   {
//     path: '/explain',
//     name: 'explain',
//     component: ExplainPage,
//     meta: { 
//       requiresAuth: false,
//       title: '쓰담: 서비스 소개',
//       metaTags: [
//         {
//           name: '서비스 소개',
//           content: '쓰담 서비스에 대해 소개합니다.'
//         },
//         {
//           property: 'og:title',
//           content: '쓰담: 서비스 소개'
//         }
//       ]
//     },
//   },

//   {
//     path: '/:pathMatch(.*)*',
//     name: 'notFound',
//     component: NotFoundPage,
//     meta: { 
//       requiresAuth: false,
//       title: '오류: 페이지를 찾지 못했습니다.',
//       metaTags: [
//         {
//           name: '페이지를 찾지 못했습니다.',
//           content: '요청하신 페이지를 찾지 못했습니다.'
//         },
//         {
//           property: 'og:title',
//           content: '오류: 페이지를 찾지 못했습니다.'
//         }
//       ]
//     },
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// })

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title || "쓰담"
//   // 어떤 화면으로 가도 프린트 모달 초기화
//   store.commit('SET_SHOW_PRINT_MODAL', false)
//   if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
//     const redirectRoute = router.resolve('login').route
//     setMetaData(redirectRoute)
//     next({ name: 'login'})
//     return
//   }
//   if (to.name == 'editor') {
//     if (store.getters.currentBook == null) {
//       const redirectRoute = router.resolve('book').route
//       setMetaData(redirectRoute)
//       next({ name: 'book'})
//       return
//     }
//   }
//   if (to.name == 'recommend') {
//     if (store.getters.currentBook == null) {
//       const redirectRoute = router.resolve('book').route
//       setMetaData(redirectRoute)
//       next({ name: 'book'})
//       return
//     }
//   }
//   if (to.name == 'revision') {
//     if (store.getters.currentPageID == null) {
//       const redirectRoute = router.resolve('editor').route
//       setMetaData(redirectRoute)
//       next({ name: 'editor'})
//       return
//     }
//   }
//   setMetaData(to)
//   next()
// })

// // meta tag set
// function setMetaData(route) {
//   document.title = route.meta.title || '쓰담';

//   // Remove any stale meta tags
//   Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

//   // Add new meta tags
//   if (route.meta.metaTags) {
//     route.meta.metaTags.map(tagDef => {
//       const tag = document.createElement('meta');

//       Object.keys(tagDef).forEach(key => {
//         tag.setAttribute(key, tagDef[key]);
//       });

//       tag.setAttribute('data-vue-router-controlled', '');

//       return tag;
//     }).forEach(tag => document.head.appendChild(tag));
//   }
// }

// export default router
