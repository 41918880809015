<template>
  <div v-if="buttonDisabled">
    <div disabled class="flex justify-center items-center w-[135px] h-[37px] relative gap-2.5 p-2.5 rounded-lg bg-[#FFEB03] hover:bg-[#FFEB03]/80">
      <img src="../../../public/assets/kakao.png" alt="kakao Logo" class="w-7 h-7 object-contain" />
      <button disabled class="flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-[#3C1E1E]">쓰담과 소통하기</button>
    </div>
  </div>
  <div v-else @click="handleClick">
    <div class="flex justify-center items-center w-[135px] h-[37px] relative gap-2.5 p-2.5 rounded-lg bg-[#FFEB03] cursor-pointer hover:bg-[#FFEB03]/80">
      <img src="../../../public/assets/kakao.png" alt="kakao Logo" class="w-5 h-5 object-contain" />
      <button class="flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-[#3C1E1E]">쓰담과 소통하기</button>
    </div>
  </div>
</template>

<script>
import { RevisionStatus } from '@/store/constants/revisionStatus'

export default {
  // props: ['buttonName'],
  emits: ['buttonClick'],
  methods: {
    handleClick() {
      this.$emit('buttonClick')
    }
  },
  computed: {
    buttonDisabled() {
      return (this.$route.name=='revision' && this.$store.getters.revisionState != RevisionStatus.LLM_DONE)
    }
  }
}
</script>